body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.my-container {
  display: flex;
  flex-direction: column;
  width: 800px;
  /* set a fixed width for the container */
  height: 500px;
  /* set a fixed height for the container */
}

.div1,
.div2 {
  flex: 1;
  /* make both divs take up equal space */
  overflow-x: scroll;
  overflow-y: scroll;
}

.div1 {
  background-color: #f1f1f1;
  /* set a background color for the first div */
}

.div2 {
  background-color: #e0e0e0;
  /* set a background color for the second div */
}

.no-spin-buttons::-webkit-inner-spin-button,
.no-spin-buttons::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
}

.custom-submit-button {
  background-color: green;
  margin-right: 10px;
}

.custom-cancel-button {
  background-color: pink;
  margin-right: 230px;
}

.ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.ul li {
  padding: 10px 10px 10px 30px;
  margin: 5px 0;
  cursor: pointer;
  position: relative;
  /* Ensure positioning for the nested list */
}

.ul li ul {
  display: none;
  /* Hide the nested list by default */
  position: absolute;
  /* Position the nested list */
  left: 0;
  /* Align it to the left of the parent */
  top: 100%;
  /* Position it below the parent */
  padding: 0;
  /* Remove padding from nested list */
  background-color: #fff;
  /* Optional: Background color for the nested list */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  /* Optional: Add some shadow for better appearance */
  list-style-type: none;
  /* Remove bullets from nested list */
  width: 300px;
  /* Optional: Adjust the width of the nested list */
}

.ul li:hover>ul {
  display: block;
  width: 330px;
  z-index: 1;
  /* Show the nested list when the parent is hovered over */
}

.ul li:hover {
  background-color: #f0f0f0;
  /* Change this color to your desired hover background color */
}

.ul li ul li:hover {
  background-color: #d0d0d0;
  /* Change this color for the submenu hover background color */
}

.navigation {
  width: 100%;
  background-color: #DE2711;
}

.img {
  width: 30px;
  border-radius: 50px;
  float: left;
  font-size: larger;
}

.logout {
  font-size: .9em;
  font-family: 'Oswald', sans-serif;
  position: relative;
  right: -5px;
  overflow: hidden;
  letter-spacing: 3px;
  opacity: 0;
  transition: opacity .45s;
  -webkit-transition: opacity .35s;

}

.button {
  text-decoration: none;
  border: none;
  float: right;
  padding: 5px;
  margin: 5px;
  color: white;
  width: 40px;
  background-color: #DE2711;
  transition: width .35s;
  -webkit-transition: width .35s;
  overflow: hidden
}

.button:hover {
  width: 130px;
}

button:hover .logout {
  opacity: .9;
}

.My-circle-Button {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background: none;
  transition: width .5s ease;
  color: #000;
  border: 4px solid #000;
  position: relative;
}

.My-circle-Button:before {
  content: "+";
  font-size: 2.6em;
  line-height: 37px;
  position: absolute;
  top: 3px;
  left: 11px;
}


.My-circle-Button:hover {
  width: 240px;
  position: relative;
}

.My-circle-Button:hover:after {
  content: "read more";
  font-size: 1.5em;
  line-height: 37px;
  position: absolute;
  right: 60px;
  top: 2px;
}

.custom-link {
  color: '#0000FF';
  text-decoration: none;
  font-weight: bolder;
}

.custom-accordion .accordion-button {
  background: linear-gradient(45deg, #0037db, #1e97c7) !important;
  color: white !important;
  /* White text */
  font-weight: 600;
  border-radius: 5px;
  transition: 0.3s ease-in-out;
}

.custom-accordion .accordion-button:not(.collapsed) {
  background: linear-gradient(145deg, #0b2ca5, #f12ef1, #17a6e9) !important;
  color: white !important;
  /* Ensure text remains visible */
}

.custom-accordion .accordion-button:hover {
  background: linear-gradient(145deg, #0b2ca5, #f12ef1, #17a6e9) !important;
}

.accordion-button::after {
  filter: invert(1);
  /* Inverts color (for white arrow) */
}

.custom-box {
  /*background: linear-gradient(135deg, #0975f0, #1e3c72);
  /* Attractive gradient */
  color: black;
  padding: 10px;
  margin: 10px;
  font-size: 15px;
  font-weight: 500;
  text-align: left;
  border: 2px solid rgba(73, 70, 70, 0.3);
  /* Light border for better visibility */
  box-shadow: rgba(0, 0, 0, 0.25) 0px 6px 15px;
  /* Slightly stronger shadow */
  border-radius: 5px;
  position: relative;
  width: 100%;
  cursor: pointer;
}

.card-shadow {
  box-shadow: 0px 4px 15px rgba(199, 4, 4, 0.5) !important;
  /* Blue Shadow */
  border-radius: 10px;
}

.cursor-pointer {
  cursor: pointer;
}

.register_login {
  text-decoration: none;
  color: #0000FF;
  font-weight: 600;
  font-size: 24px;
}

.register_login:hover {
  color: #0000FF;
  /* Keep the same color on hover */
}